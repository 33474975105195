import { render, staticRenderFns } from "./RouteView.vue?vue&type=template&id=28872868"
import script from "./RouteView.vue?vue&type=script&lang=js"
export * from "./RouteView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\KRHealth\\code\\develop\\kr-web-ctms\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('28872868')) {
      api.createRecord('28872868', component.options)
    } else {
      api.reload('28872868', component.options)
    }
    module.hot.accept("./RouteView.vue?vue&type=template&id=28872868", function () {
      api.rerender('28872868', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Layouts/RouteView.vue"
export default component.exports