<template>
	<div class="router-view-wrap" style="height: 100%">
		<keep-alive>
			<router-view v-if="isKeepAlive" />
		</keep-alive>
		<router-view v-if="!isKeepAlive"></router-view>
	</div>
</template>

<script>
export default {
	name: "RouteView",
	computed: {
		isKeepAlive() {
			return this.$route.meta.keepAlive
		}
	}
}
</script>
